
@import "../../../app-customs/config-scss/variables.scss";


.list-page-container {
    display       : flex;
    flex-direction: column;
    overflow-y    : hidden;
    // -webkit-overflow-scrolling: touch;
    height: 100%;
    .list-page-header {
        box-sizing: border-box;
        padding: 1rem 0 1.5rem 0;
        background-color: $color1;
        min-height: calc((100vh - 1px) / 7 + 1px);
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #000;
    }
    .go-back-cat {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color1;
        overflow: visible;
        text-overflow: ellipsis;
        // padding: 2.2rem 0;
        flex: 1;
        
        span.gi {
            position: absolute;
            background-color: white;
            left: 0.5em;
            top: calc((100vh - 1px) / 33 + 1px);
            font-size: 3em ;
        }
        .go-back-title {
            padding: 0.2rem 2.1em;
            flex: 1;
            text-align: center;
            font-size: 1.5em;
            display: inline-block;
        }
    }
}
.list-page-content {
    display       : flex;
    flex-direction: column;
    flex-grow     : 1;
    flex-shrink   : 1;
    overflow-y    : hidden;
    // height: 100%;
    & > div {
        flex-shrink: 0;
    }

}

