
@import "../../../app-customs/config-scss/variables.scss";


[id=search-place-dialog] {
    height: 100%;
    .list-dialog-content {
        padding: 0 .5em .5em;
        font-size: .9em;
        max-height: 600px;
        height: 100%;
    }

    .no-result {
        margin: .5em 0 .3em;
    }

    .search-field-input-container {
        flex-basis: 75%;
    }
}

#search-place-container {
    text-transform: capitalize;
    font-variation-settings: 'wght' 500;
    &::before{
        content: attr(data-instructions);
        position: absolute;
        display: flex;
        justify-content: center;
        color: $lightGrey;
        align-items: center;
        font-size: xx-large;
        text-transform: none;
        height: calc(100% - 310px);
        width: inherit;
    }
    position: absolute;
    background-color: #ECEBED;
    width: 100%;
    height: 100%;
    top: 0;
    transition: all 0.2ms;
    padding-top: 30px;
    .list-dialog-content {
        padding: 4.2% 12.3%;
        // font-size: .9em;
        max-height: calc(50vh);
        height: 100%;
        > div:first-child{
            margin-top: 3em;
        }
        > div:last-of-type {
            margin-bottom: 3em;
        }
        .no-result {
            text-transform: none;
            font-size: 2em;
            color: $greyColor;
        }
    }
    .btn-close-keyboard {
        position: fixed;
        top: 1.9rem;
        right: 1.9rem;
        // padding: 1rem 1rem;
        width: 75px;
        height: 75px;
        z-index: 105;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color1;
    }
    .type-bar {
        background-color: $greyColor;
        padding: 1em 0;
        .type-bar-label {
            padding-left: 1.5rem;
            font-size: 1.5em;
            text-align: left;
            font-weight: lighter;
        }
    }
    .type-bar-content {
        .list-component {
            background-color: #fff;
            color: $greyColor;
            .list-collapse{
                > li {
                    padding: 1em 1.5em;
                    background-color: #fff;
                    border: 1px solid #000;
                    .list-el-text {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .text-minor {
                        color: $listTextMinorColorAlpha;
                    }
                }
            }
        }
    } 
}

/* rules for tiny screens */
@media screen and (max-height: 780px) {

    [id=search-place-dialog] {

        .search-field-container {
            padding: .3em .2em 0 .4em;
        }

        .list-dialog-content {
            margin-top: .2em;
            .list-component {
                height: 300px;
                overflow-y: auto;
            }
        }
    }
}

#sp-inner {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.sp-buttons-container {
    display: flex;

    &.modal {
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 10px;
    }

    &.start {
        justify-content: flex-end;
    }
    &.dest {
        justify-content: space-between;
        align-items    : center;
    }

    // Common button style
    & > div {
        margin       : 0 .4em .4em;
        padding      : .4em .7em .3em;
        font-weight  : bold;
        color        : white;
        border-radius: 3px;
        cursor       : pointer;
    }

    .btn-edit-start {
        font-size: .9em;
        height   : fit-content;
        padding-bottom: .4em;
        background-color: #949FA7;
    }

    .btn-choose-dest,
    .btn-compute-route {
        background-color: $ctaBtnBackgroundColor;
    }

    .btn-compute-route {
        padding: .6em 1.6em;
    }


}

.simple-keyboard .lang {
    max-width: 80px;
}

.keyboardContainer {
    .search-field-container{
        border-top: 1px solid #F1F1F2;
        background-color: #fff;
        max-height: 80px;
        .search-field-input-container {
            max-width: 60%;
            font-size: x-large;
        }
    }
    .hg-button {
        height: 60px;
        font-weight: bolder;
        &.capitalize {
            text-transform: uppercase;
        }
    }
    .hg-button-space {
        // margin: 0 10%!important;
    }

    .small-btn {
        max-width: 80px;
    }

}