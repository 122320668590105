@import "../../../app-customs/config-scss/variables.scss";

.fiche-description {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    
    color: $color2;
    // padding-top: 2em;
    .logo {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        img {
            width: 100%;
            height: 100%;
        }
        flex: 0.66;
        height: 64%;
        border-bottom: 0.64em solid #867B7B;
    }
    .fiche-contact {
        // flex: 0 31.7%;
        flex: 0 40%;
        // border-left: 1px solid #F0EFF0;
        min-height: 50%;
        display: flex;
        flex-direction: column;
        &-item {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            // margin-left: 18%;
            flex: 1;
            &:first-child{
                margin-top: 0;
            }
            > * {
                text-align: left;
            }
            i {
                display: flex;
                align-items: center;
                margin-right: 2px;
                height: 42px;
                width: 42px;
                > img {
                    width: 1.3em;
                }
            }
            &.round-border {
                width: 1em;
                height: 1em;
                background-color: #867B7B;
                border-radius: 1em;
            }
            span {
                max-width: 80%;
                font-variation-settings: 'wght' 700;
                word-wrap: break-word;
            }
        }
    }

    .img-full-width img {
        position : relative;
        // left     : 50%;
        max-width: 100%;
        // -webkit-transform: translateX(-50%);
        // transform: translateX(-50%);
    }

    .img-left {
        float: left;
        margin-right: 10px;

        img {
            max-width: 150px;
            height: auto;
        }
    }

    .prop-name {
        // margin-bottom: 8px;
        display: flex;
        flex: 0.36;
        align-items: center;
        overflow: hidden;
        border: 1px solid #F0EFF0;
        .bottom-shadow {
            -webkit-box-shadow: inset 0px -41px 38px 0px rgba(255, 255, 255, 1);
            -moz-box-shadow: inset 0px -41px 38px 0px rgba(255, 255, 255, 1);
            box-shadow: inset 0px -41px 38px 0px rgba(255, 255, 255, 1);
            height: 200px;
            width: 100%;
            z-index: 100;
            position:absolute;
        }

        .prop-description {
            padding: 1rem 0 1rem 3.6%;
            margin-right: 0.5rem;
            user-select: none;
            position: relative;
            box-sizing: border-box;
            flex: 1;
            // overflow: auto;
            height: 100%;
            display: flex;
            align-items: center;
            &.preview {
                max-height: 200px;
                overflow: hidden;
                z-index: 0;
            }

            p {
                font-size  : 1.2em;
                padding-right: 1rem;
                margin     : 0 5px 0 5px;
                text-align : left;
                max-height: 100%;
                overflow: auto;
            }
        }

        .btn-expand {
            float: right;
            cursor: pointer;
            text-decoration: underline;
            color: $color3;
            margin-right: 2.2em;
            line-height: 2em;
        }
    }
}
