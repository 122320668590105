@import "../../../app-customs/config-scss/variables.scss";

.alphalist-component {
    display: flex;
    height: 100vh;
    &.side-index-right {
        flex-direction: row-reverse;
    }
}

.alphalist-container {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    // &::-webkit-scrollbar{
    //     position: absolute;
    // }
    .list-component {
        .list-el-text {
            color: #000;
            font-size: 1rem;
            .list-el-details {
                // margin-top: 0.4rem;
                .text-minor{
                    color: $listTextMinorColorAlpha;
                    font-size: 0.87rem;
                    // flex: -moz-available;
                    &.right-text {
                        text-align: right;
                    }
                }
            }
        }
        .list-collapse{
            > li {
                padding: 0.5em 10px 0.5em 1em;
                background-color: #fff;
            }
            li.horizontal-separator {
                background-color: #F2F2F2;
                justify-content: flex-start;
                text-align: start;
                border-bottom: 1px solid #000;
                .separator-text {
                    flex-grow: 1;
                    padding: 1rem 0;
                  }
            }
        }
    }
}

// List on the right, index on the left
.alphalist-container-right {
    order: 1;
}