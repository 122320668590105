@import "../../../app-customs/config-scss/variables.scss";

.flag-select {
    margin: 0 .2em;
    height: 34px;
    width : 34px;
    background-size: 34px 34px;
    -webkit-tap-highlight-color: transparent;
}

.lang-options {
    cursor: pointer;
    margin: 0 .2em;
    height: 5em;
    width : 5em;
    // background-size: 34px 34px;
    -webkit-tap-highlight-color: transparent;
    // border: 1px solid $color1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-large;
    // background-color: $greyColor;
    color: $color1;
    border: 2px solid $color1;
    text-transform: capitalize;
    &.is-current-lang {
        background-color: $homeToolbarBackgroundColor;
    }
}