
@font-face {
  font-family: 'printania-sans';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/PrintaniaSans-Regular.woff') format('woff'),
       url('../public/files/project/fonts/PrintaniaSans-Regular.ttf') format('ttf');
}
.title-font,
.content-font {
  font-family: 'Urbanist';
}

@font-face {
  font-family: 'printemps-2017';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/printemps2017.woff') format('woff'),
       url('../public/files/project/fonts/printemps2017.ttf') format('ttf');
}
@font-face {
  font-family: 'Urbanist';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/Urbanist-VariableFont_wght.ttf') format('truetype');
}

.icon-font {
  font-family: 'printemps-2017';
}