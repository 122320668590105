@import "../../../app-customs/config-scss/variables.scss";

#choose-lang-fullScreen-container {
    position: absolute;
    z-index: 10099;
}

.choose-lang {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    // background: url('../../../app-customs/public/lib/img/haussmann_logo.png') right bottom no-repeat;
    background-color: white;
    background-size: contain;
    .lang-options {
        width: 10vw;
        height: 17vh;
        text-transform: uppercase;
        margin: 0 2rem;
        font-family: 'Urbanist';
        letter-spacing: 1.2px;
    }
    .lang-options {
        
    }
    .meta-app-version {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

.minor-text-info {
    color: #aaa;
    font-size: 0.7rem;
}