
@import "../../app-customs/config-scss/variables.scss";

$verticalLineColor: #EDBA23;
$horizontalSeparatorBorder: 1px solid #eee;



.fiche-row1 {
    display: flex;
    margin: 1em;
}

// COLUMN 1

.fiche-row1-col1 {
    flex: 0 0 45%;
    max-width:200px;
    margin-right: 1em;
}
.fiche-photo {
    width: 100%;
}

.mobigeo-modal-content-message{
    margin-top: 30px;
}



// COLUMN 2

.fiche-row1-col2 {
    a {
        font-size: 1.8em;
    }
}

.fiche-subtitle {
    margin-bottom: 1em;
    font-size: .95em;
}
.fiche-paragraph {
    margin-bottom: 1em;
    font-size: .95em;
}
.fiche-logo {
    max-width: 120px;

    img {
        margin-top: 1em;
        max-width: 100%;
        max-height:70px;
    }
}


// ROW 2
.fiche-row2 {
    margin-bottom: 1em;

    .free-row {
        border-width: 0;
    }
}



.fiche {
    display: flex;
    flex-direction: column;
    background-color: $ficheBackgroundColor;
    height: 70%;
    width: 78%;
    max-width: 52vw;
    // still used ?
    .list {
        border-bottom:none;
    }

    .all-informations {
        display       : flex;
        flex-direction: column;
        flex-grow     : 1;
        position      : relative;
        // margin-bottom : 10%;
        background    : #fff;
        height: 100%;
        & > .prop-name {
            padding-bottom: 0.2em;
            border-bottom : 1px solid #eee;
            display: flex;
        }
        .detail-data-list {
            min-height: fit-content;
            height: fit-content;
        }
    }

    .description {
        margin: .5em 0 0 1em;

        small {
            font-size: .8em;
        }
        div {
            margin-left: 1em;
        }
        img {
            margin-top: 1em;
            max-width : 100%;
        }
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        .icon-font {
            margin-top: -4px;
            font-size : $ficheIconsSize;
            width: $ficheIconsSize;
            height: $ficheIconsSize;
            display: block;
            text-align: center;
        }
        flex-shrink: 7;
    }

    .content-font {
        font-size: 16px;
    }

    .fiche-related-data {
        margin-bottom: .3em;
        user-select: none;

        .prop-img {
            margin-left: 1em;
            padding: .4em 0;
            border-bottom: $horizontalSeparatorBorder;
        }
    }
    .fiche-related-data:first-child {
        margin-top: .5em;
    }
    .fiche-related-data:last-child {
        margin-bottom: 0;

        .prop-img {
            border-bottom: 0;
        }
    }

    .high-row .prop-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }


    .detail-exhibitor,
    .free-row {
        margin : .6em 0 0 1em;
        padding: 0 .3em .6em 0;
        border-bottom: $horizontalSeparatorBorder;
    }
    .free-row:last-child {
        border-bottom: 0;
    }

    .row-count {
        height     : 47px;
        overflow-y : hidden;
        padding-top: 3px;
    }

    .nouveautes.title-font,
    .evenements.title-font,
    .aeronefs.title-font {
        font-size: 16px;
        color    : #005EA7;
    }

    .activity {
        & > div {
            width: inherit;
        }
        &.title-font span {
            color: #005EA7;
        }
    }

    .localisations {
        float  : left;
        margin : 0;
        padding: 0;
        list-style-type: none;

        .link {
            font-size: 16px;
            color: #005EA7;
        }

        .info {
            font-size: 14px;
            color: $fontColor;
            font-style: italic;
            margin-left: 0.8em;
        }

        li {
            margin: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        li:last-child {
            margin-bottom: 7px;
        }
    }

    .prop-img {
        display: flex;

        .prop-right {
            flex-grow: 1;
        }
    }

    .prop-right {
        flex-basis: 50%;
        word-break: break-word;
    }
    .prop-right.title-font .link {
        font-size: 16px;
        color    : #005EA7;
    }

    .prop-left {
        display        : flex;
        flex-basis     : 18%;
        flex-shrink    : 0;
        justify-content: center;
        align-items    : center;
        margin-right   : 1em;

        .label {
            // fix iOS bug
            font-size: 1em !important;
        }
    }

    .prop-right-fleche {
        display        : flex;
        justify-content: center;
        align-items    : center;
        padding-right  : 2%;
        padding-left   : 10px;
    }

    .fiche-separator {
        display         : inline-block;
        width           : 100%;
        height          : 1em;
        text-align      : left;
        font-weight     : bold;
        background-color: #f2f2f2;
        padding         : 8px 0px 7px 5px;
        // text-transform  : capitalize;
        user-select     : none;
    }
    .is-fav {
        color: $favIconColor;
    }

    .content .icon-font .link {
        background-color      : $color2;
        fill: $color2;
        margin-left: 10px;
        text-align: center;
        width: 2em;
        height: 1.4em;
        display: block;
    }
    span.fiche-icon {
        height: 1.7em;
        width: 1.7em;
        background-color    : $color2;
    }
    span.addressName {
        display: block;
    }

    .chevron-right-icon:before {
        background-color: $chevronColor;
    }

    .count {
        margin-right: .3em;
        font-size   : 1.8em;
        font-weight : 800;
    }
    .fleche {
        float: right;
    }
    .nouveautes {
        display: block;
    }


    .entites-content {
        padding-bottom: 7px;
        margin-top    : 5px;
        width         : 100%;
        border-bottom: $horizontalSeparatorBorder;

        &:last-child {
            border: none;
        }
    }

    .content-entities {
        width: 70%;
        float: right;
    }

    .activities-content {
        display : flex;
        padding : .3em 0 .4em;
        align-items: center;

        & > span:first-child {
            flex         : 1 1 auto;
            overflow     : hidden;
            text-overflow: ellipsis;
            min-width    : 0;
        }

        & > span:not(:last-child) {
            border-bottom: 1px solid lightgrey;
        }

        .prop-right-fleche {
            padding-left: 0;
        }
    }

    .detail-exhibitor   .activities-content {
        margin-bottom: 0;
    }

    .color-grey-dark {
        color: #595959;
        font-weight: 600;
    }

    .txt-right {
        text-align:right;
    }
}


.social-icons-container {
    font-size: 1.8em;
    color: $color1;
    display: flex;
    a {
        margin-right: .5em;
    }
}


@media only screen and (min-width: 768px) {
    .fiche {
        .title-font {
            font-size: 24px;
        }
        .title .share-btn {
            margin-top: .2em;
        }
        .title .note-btn {
            margin-top: .2em;
        }
        .content-font {
            // font-size: 22px;
        }
        .content .icon-font {
            width    : 46px;
            height   : 46px;
            font-size: 46px;
        }
        p {
            font-size: 22px;
            line-height: 26PX;
        }
        .prop-img .prop-right {
            line-height: 26PX;
        }
        .prop-right.title-font .link {
            font-size: 22px;
        }
        .prop-left {
            display: flex;
            align-items: center;
            width: 110px;
        }
        .localisations .link {
            font-size  : 22px;
            line-height: 26PX;
        }
    }

}

.generic-page-body {
    flex-direction: column;
    height: 100%;
    background-color: $ficheBackgroundColor;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid $color2;
    .detail-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // align-items: center;
        text-align: left;
        background-color: $color1;
        color: $titleFontColor;
        min-height: calc((100vh - 1px) / 7 + 1px);
        padding: 1rem 0 0.5rem 0;
        box-sizing: border-box;
        border-bottom: 1px solid $color2;
        font-size: 1.5em;
        // margin-bottom: 2em;
        & .detail-title-container {
            display: flex;
            flex: 1;
            flex-direction: column;
            // letter-spacing: 1px;
            // padding: 1rem 1em 0 3rem;
            padding: 0 2rem 1rem 4.8rem;
            // word-break: break-word;
        }
        & .detail-title {
            // font-size: 1em;
            // padding: 0.2rem 1.6em 0 1.6em;
            font-variation-settings: 'wght' 900;
            line-height: 1;
            margin: 10px 0 10px 0;
        }
        .detail-place {
            font-size: 1em;
        }
        & .go-back-cat {
            position: relative;
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            // padding-top: 0.5rem;
            // margin-bottom: 1.3rem;
            span.gi {
                background-color: #fff;
                position: absolute;
                top: calc((100vh - 1px) / 33 + 1px); // 7^2 = 49 7: because 7elements menu
                left: 0.5em;
                font-size: 2em ;
            }
        }
    }
}

.display-none-f {
    display: none;
}
.iti-summary {
    margin-right: 3%;
    .iti-summary-row {
        display: flex;
        flex-direction: row;
        margin-left: 1.5rem;
        flex: 1;
        .iti-summary-icon {
            flex: 0.1;
            align-items: flex-start;
            justify-content: flex-start;
            &.iti-summary-icon-dest {
                display: flex;
                justify-content: center;
                align-items: end;
                min-width: 40px;
                min-height: 50px;
                &:before {
                    position: absolute;
                    content: '';
                    width: 30px;
                    height: 30px;
                    background-image: url(../../app-customs/public/icons/pin-arrived-white.svg);
                    background-position: center;
                    background-size: 1em;
                    background-repeat: no-repeat;
                    background-color: $itiTextContentColor;
                    border-radius: 100%;
                    padding: 5px;
                    z-index: 1;
                    align-self: center;
                }
                &::after {
                    content: '';
                    position: relative;
                    border-left: 3px solid $verticalLineColor;
                    z-index: 0;
                    height:50%;
                    align-self: flex-start;
                    padding-top: 20px;
                }
                & ~ .iti-rs-dest {
                    align-self: center;
                }
                
                // content: attr(data-rs-index);
                // font-size: 1.5rem;
            }
            &.iti-summary-icon-step {
                display: flex;
                justify-content: center;
                align-items: center;
                &::before {
                    content:'';
                    min-width: 40px;
                    height: 100%;
                    mask-image: url(../../app-customs/public/icons/chevron-down.svg);
                    mask-position: center;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    background-color: #EDBA23;
                }
                &::after {
                    content: '';
                    position: relative;
                    right: 21px;
                    height: 100%;
                    border-left: 3px solid $verticalLineColor;
                }
            }
            &.iti-summary-icon-start {
                display: flex;
                justify-content: center;
                &::before{
                    content: attr(text-ind);
                    color: #fff;
                    font-size: 0.51rem;
                    background-image: none;
                    text-transform: uppercase;
                    background-color: $itiTextContentColor;
                    border-radius: 100%;
                    padding: 5px;
                    height: 30px;
                    width: 30px;
                    font-variation-settings: 'wght' 800;
                    z-index: 1;
                    align-self: center;
                }
                & ~.iti-summary-label{
                    color: $itiTextContentColor;
                    font-size: 1.4rem;
                    align-self: flex-start;
                    font-variation-settings: 'wght' 500;
                    padding-top: 4px;
                }
                &::after{
                    content: '';
                    position: relative;
                    height: 50%;
                    min-height: 5px;
                    padding-bottom: 20px;
                    border-left: 3px solid $verticalLineColor;
                    left: -21px;    
                    z-index: 0;
                    align-self: flex-end;
                }
            }
        }
        .iti-rs-text, .iti-summary-label {
            flex: 1;
            // font-size: 1.2em;
            text-align: left;
            margin-left: 0.5rem;
            // padding-top: 4px;
            align-self: center;
        }   
    }
    .iti-routing-steps {
        display: flex;
        flex-direction: column;
        // margin: 2.5rem 0;
        .iti-routing-step {
            display: flex;
            flex: 1 50px;
            &:not(:first-child){
                // margin-top: 1rem;
            }
            .iti-rs-text{
                color: #000
            }
        }
    }
    > .iti-summary-row {
        margin-top: 0rem;
    }
}

// .iti-summary {
//     .iti-summary-row {
//         display:flex;
//         flex-direction:row;
//         align-items:center;
//         & .iti-rs-text {
//             flex: 1;
//             text-align: left;
//             margin-left: 1rem;
//         }
//     }
// }
.mobigeo-modal-content-message {

}

.iti-header-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 1.7em 1rem 1.7em;
    // flex: 0.5;
    text-transform: uppercase;
    > div {
        position: relative;
        font-size: 1.7em;
        font-variation-settings: 'wght' 800;
        color: $color2;
        i {
            content: "\F14E";
            margin-right: 1rem;
            color: $color2;
        }
    }
    .pmr-container-toggle {
        padding: 0;
        margin: 0;
        > span {
            color: $greyColor;
        }
    }
}

.iti-rs-text{
    // font-size: 1em;
    color: $greyColor;
    &.iti-rs-dest {
        font-size: 1.4rem;
        color: $itiDestLabelColor;
        font-variation-settings: 'wght' 500;
        // padding-bottom: 6px ;
    }
}

canvas#iti-qr-code {
    // height: 100px!important;
    // width: 100px!important;
    &.showQrCode {
        display: block;
    }
    display: none;
}

.iti-qr-code {
    &-container {
        width: 64%;
        margin: auto;
        margin-bottom: 2rem;
    }
    &-instruction{
        font-size: 1.2rem;
        font-weight: lighter;
        color: $greyColor;
    }
}

.detail-container{
    position: relative;
    display: flex;
    flex-direction: column;
    // height: 100vh;
    .modal-pop-details {
        position: fixed;
        z-index: 9;
        top: 0;
        left: $leftColumnsWidth;
        height: calc(100vh);
        background: #F9F9F9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2em;
        width: -moz-available;
        .btn-close-modal {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute; 
            top: 1.9rem;
            right: 1.9rem;
            width: 75px;
            height: 75px;
            color: white;
            background-color: $color1;
            cursor: pointer;
        }
    }
    .MuiListItem-root {
        width: auto;
        padding: 0;
        flex: 1;
        justify-content: flex-end;
    }
    .btn-expand {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0rem 0em -3.5rem 0;
        padding: 2em 20px 2em 0;
        font-size: 1.1rem;
        color: $color1;
        > i {
            padding-left: 0.5rem;
            padding-top: 0.1rem;
            font-size: 2rem;
            display: flex;
            align-items: center;
        }
    }
    #itinerary-container {
        // margin-bottom: 3.5em;
    }
    .detail-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 1em;
    }
}
