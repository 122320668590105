@import "../../../app-customs/config-scss/variables.scss";


.full-star {
    background-color: $favIconColor;
    mask-image: url(../../../app-customs/public/icons/favoris1.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.empty-star {
    background-color: $favIconColor;
    mask-image: url(../../../app-customs/public/icons/favoris2.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.full-star-fiche {
    background-color: white;
    mask-image: url(../../../app-customs/public/icons/favoris1.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.empty-star-fiche {
    background-color: white;
    mask-image: url(../../../app-customs/public/icons/favoris2.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.search {
    background-color: $appToolbarColor;
    mask-image: url(../../../app-customs/public/icons/magnifingGlass.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.share {
    background-color: $shareBtnColor;
    mask-image: url(../../../app-customs/public/icons/share.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.chevron {
    background-color: $listChevronColor;
    mask-image: url(../../../app-customs/public/icons/triangle-droite-gris.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: auto;
    height: 1em;
    width:  1em;
    &.chevron-back {
        transform: rotate(180deg);
    }
}

.back-icon {
    background-color: $appToolbarColor;
    mask-image: url(../../../app-customs/public/icons/fleche.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.pmr-icon {
    // background-image: url(../../../app-customs/public/icons/pmr.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    height: 1em;
    width: 1em;
}
.phone-icon {
    mask-image: url(../../../app-customs/public/icons/phone-borne.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 75.5%;
    height: inherit;
    width: inherit;
    background-color: #76777A;
}

.email-icon {
    background-image: url(../../../app-customs/public/icons/contact.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    height: inherit;
    width: inherit;
}

.menu {
    background-color: $appToolbarColor;
    mask-image: url(../../../app-customs/public/icons/burger.svg);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 50%;
    height: 1em;
    width: 1em;
}

.phone-row {
    background-image: url(../../../app-customs/public/icons/telephone.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; 
    height: 3em;
    width: 3em;
}

.link-row {
    background-image: url(../../../app-customs/public/icons/lien.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; 
    height: 3em;
    width: 3em;
}

.email-row {
    background-image: url(../../../app-customs/public/icons/contact.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; 
    height: 3em;
    width: 3em;
}

.map-row {
    background-image: url(../../../app-customs/public/icons/map.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%; 
    height: 3.5em;
    width: 3.5em;
    margin-left: -0.5em;
}

@media (min-width:768px) {
    .map-row {
        height: 2.5em;
        width: 2.5em;
    }
}    