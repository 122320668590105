@import "../../app-customs/config-scss/variables.scss";

/* Common rules components: Menu, ChooseLangDialog, ChooseLangInline */
.flag-fr,
.flag-en,
.flag-zh {
  display: inline-block;
  background-repeat: no-repeat;
}
.flag-fr {
  background-image: url("../../app-customs/public/icons-svg/flag/flag-fr.png");
}
.flag-en {
  background-image: url("../../app-customs/public/icons-svg/flag/flag-en.png");
}
.flag-de {
  background-image: url("../../app-customs/public/icons-svg/flag/flag-de.png");
}
.flag-zh {
  background-image: url("../../app-customs/public/icons-svg/flag/flag-zh.png");
}

/* Favorite icon */
.star {
  font-size: $favIconSize;
  color: $favIconColor;
  vertical-align: middle;
  //transition: color 0.4s, opacity 0.4s;
  //opacity: 0.7;
}
.star.is-fav,
.is-fav .star {
  color: $favIconColor;
  opacity: 1;
}

/* Used by ListElement, TypeBar */
.horizontal-separator {
  justify-content: center;
  width: inherit;
  // padding: 7px 8px 5px !important;
  flex-shrink: 0;
  align-items: center;
  text-align: center;
  // font-weight: bold;
  color: $separatorColor;
  background-color: $separatorBackgroundColor;
  // border: 0 !important;
  overflow: hidden;

  .separator-text {
    flex-grow: 1;
  }
}

/*
.alphalist-container .horizontal-separator,
#list-page .horizontal-separator {
    color: $indexHorizontalBarColor;
    background-color: $color1;

    .type-bar-icon,
    .type-bar-label {
        color: $indexHorizontalBarColor;
    }
}*/

// Form

.generic-field-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.3em 0;

  label {
    padding-right: 0.8em;
  }

  input {
    margin: 0 1em;
    font-size: 1em;
    min-width: 180px;
    padding: 0.65em 0.6em 0.45em;
  }
}

.input-modal .generic-field-row {
  height: inherit;
  overflow-y: auto;
  .input-simple {
    // flex: 1;
    text-align: center;
  }
}

.generic-field-row.generic-form-error input {
  border: 1px solid #ffa67b;
}

.generic-field-row-chkbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 0.5em 0.7em 0.4em 0.6em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  div {
    display: flex;
    width: 100%;
    align-items: center;
    flex-basis: 1.8em;
    opacity: 0.7;
    margin-bottom: 1em;
  }
  div:last-child {
    margin-bottom: 0;
  }

  .generic-chkbox-row-checked {
    opacity: 1;
  }

  .generic-chkbx {
    flex: 0 0 auto;
    background-color: $color1;
    stroke: $color1;
    stroke-width: 1em;
    fill: none;
  }

  .generic-chkbx-checked {
    flex: 0 0 auto;
    background-color: $color1;
    fill: $color1;
    stroke: none;
  }

  
  .generic-chkbx-label {
    flex-grow: 1;
    align-self: center;
    padding: 0.15em 0 0 0.7em;
    line-height: 1.1em;
  }
}

.generic-page-body {
  text-align: center;
  .iti-qr-code {
    margin: 1rem auto;
  }
}
