
[id=choose-lang-inline-container] {
    margin-top     : .8em;
    display        : flex;
    justify-content: flex-end;
    user-select    : none;
}

.round-corner {
    border-radius: 0 0 45px 0;
}

.choose-lang-instruction {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 13%;
    color: #A7A7A7;
    font-size: 1.77em;
    text-align: center;
}