

$pageBackgroundColor: white;
$ficheBackgroundColor: white;


$color1: #00D072; // ~green
$color1lighter: lighten( $color1, 10% );

$color2: #000; //black

$color3: #9A9A9F;

$color4: #E7E7E7;
$grey: #76777B;

$greyColor: #5E6167;

$fontColor: #1f1f1f;

$titleFontColor: white;

$lightGrey: #B0B0B0;

$linkColor: #0089D9;

$warningColor: #ffc107;

$list-el-event: #42c7a8;
$endHourColor:  $color1;

/* Two columns mode */
$leftColumnsWidth: 34vw;
$rightColumnsWidth: 66vw;

/* Call-to-action button (e.g main button on modals) */
$ctaBtnBackgroundColor: $color1;

/* App toolbar */
$appToolbarHeight: 48px;
$appToolbarColor: $color2;
$appToolbarBackgroundColor: $color4;

/* Fiche */
$favIconSize: 25px;
$ficheIconsSize: 34px; // related data below description: places, events, etc
$ficheIconColor: $color2;
$ficheIconsColor: $color2;

/* Lang */
$currentLangBorderColor: #3eaac3;
/* in menu */
$menuLangIconSize: 2.4em;
$menuLangIconPosition: 20% center;
$menuItemHoverBackgroundColor: $color1lighter;
$menuItemIconsBackgroundColor: $color2;
$menuBgColor: #fff;


/* Home tile click effect */
$tileEffectScale: .96;
$tileEffectColor: white;
$tileEffectOpacity: .3;

$tileIconSize: 48px;
$tileTextColor: $fontColor;

/* Home toolbar */
$homeToolbarColor: #838D94;
$homeToolbarBackgroundColor: $color1;
$homeToolbarBackgroundColorHover: $color1lighter;

$inboxCountBackgroundColor: white;
$inboxCountColor: $color1;

$homeBackgroundColor: #f6f6fa;

/* Banner component (!= AdBanner) */
$bannerBackgroundColor: transparent;
$bannerMaxHeight: 90px;

/* Share button */
$shareBtnColor: white;

/* List */
$favIconColor: $color3;
$listElBackgroundColorHover: #f2f2f2;
$listChevronColor: black;
$listBorderColor: $color2;
@mixin emphasized() {
    color: #FF0614;
}
$indexHorizontalBarColor: white;

/* @see .horizontal-separator, used by ListElement, TypeBar */
$separatorBackgroundColor: #787676;
$separatorColor: white;


$listTextMinorStrongColor: #7b7b7b;
$listTextMinorStrongFontSize: 1em;

$listTextMinorColorAlpha: #000;
$listTextMinorColor: #000;
$listTextMinorFontSize: .7em;

$listElCounterBackgroundColor: #e2e2e2;
$listElementImageSize: 50px;
$listElementImageWidth: 60px;
$listElementImageSmallWidth: 32px;

$listBackgroundColor: #fff;
$listTitleColor: #000;
$listColorActive: $color1;

$appToolbarBackgroundColor: #fff;


/* Side index */
$sideIndexColor: black;
$sideIndexGradient: $color4; //linear-gradient(to bottom, $color1, #e5005f, #e10068, #dd0071, #d8007a);
$sideIndexSelectedBackgroundColor: $color1;
$sideIndexSelectedColor: white;
$sideIndexBackgroundColor: $color4;

/* Search */
$searchFieldHeight: 3.5em;
$searchFieldBackgroundColor: #f2f2f2;
$searchFieldBorderColor: transparent;
$searchFieldClearButtonColor: white;
$searchFieldClearButtonBgColor: $color2;
$searchFieldColor: #585858;

/* Input */
$placeholderText: #656555;

/* TypeBar */
$typeBarIconColor: white;
$typeBarColor: white;

/* Mobile-Spot Bar */
$mobileSpotBarColor: $fontColor;
$mobileSpotBarBackgroundColor: indigo;

/* Misc */
$chevronColor: $fontColor;


/* Filter dialog colors */
$fdLightest: #f8f8f8;
$fdLighter: #e3e3e3;
$fdLight: #d7d7d7;
$fdMid: #a6a6a6;
$fdDarker: #6d6d6d;
$fdDarkLight: #8d8d8d;
$fdSelected: #008b3d;
$fdSelectedIcon: #ff7923;
$fdWarningColor: #ff7923;
$fdLinkColor : #60b3db;

/* Synoptic agenda */
$synoColumnWidth: 116px;
$synoBackgroundColor: white;
$synoPlacesRowHeight: 60px;
$synoBorderColor: lightgrey;
$synoHoursColumnWidth: 1.6em;
$synoEventTextColor: white;

/* image upload */
$imageUploadCameraBtnColor: lighten( $color1lighter, 15% );
$imageUploadGalleryBtnColor: lighten( $lightGrey, 15% );

/* Klipso Leads */
$klTextColor: #646464;

$klThemeColor1: #ba0c42;
$klThemeColor2: #f9ce01;

$klHeaderColor: $fontColor;
$klHeaderBgColor: $klThemeColor2; // See also config/cordovaStatusBarConfig.js
$klHeaderHeight: 3em;
$klHeaderCloseBtnColor: $klTextColor;

$klContentColor: $fontColor;
$klContentBgColor: $pageBackgroundColor;

$klStatusBlockBgColor: #efefef;
$klAllSyncColor: #00bf00;

$klCheckColor: #00bf00;
$klNotSyncColor: $klThemeColor1;

$klButtonsColor: white;
$klButtonsBgColor: $klThemeColor1;

$klFooterHeight: 3.5em;

$sortSearchBarHeight: 1.6em;

$klFieldBorder: 1px solid lightgrey;

$klOverlayLoaderBgColor: rgba(255, 255, 255, 0.7);
$klOverlayLoaderLabelBgColor: white;

/* image upload */
$imageUploadCameraBtnColor: lighten( $color1lighter, 15% );
$imageUploadGalleryBtnColor: lighten( $lightGrey, 15% );

/* image crop */
$imageCropCancelBtnColor: grey;
$imageCropConfirmBtnColor: #39b939;


/* KLIPSO LEADS */

$klTextColor: #646464;

$klThemeColor1: #ba0c42;
$klThemeColor2: #f9ce01;

$klHeaderColor: $fontColor;
$klHeaderBgColor: $klThemeColor2; // See also config/cordovaStatusBarConfig.js
$klHeaderHeight: 3em;
$klHeaderCloseBtnColor: $klTextColor;

$klContentColor: $fontColor;
$klContentBgColor: $pageBackgroundColor;

$klStatusBlockBgColor: #efefef;
$klAllSyncColor: #00bf00;

$klCheckColor: #00bf00;
$klNotSyncColor: $klThemeColor1;

$klButtonsColor: white;
$klButtonsBgColor: $klThemeColor1;

$klFooterHeight: 3.5em;

$sortSearchBarHeight: 1.6em;

$klFieldBorder: 1px solid lightgrey;

$klOverlayLoaderBgColor: rgba(255, 255, 255, 0.7);
$klOverlayLoaderLabelBgColor: white;

// Klipso Leads questionnaire screen

$klFormMarginLeft: 50px;

$klFormContactRow1BgColor: #efefef;
$klFormCompanyIconColor: $klThemeColor1;
$klFormCompanyNameColor: $klThemeColor1;
$klFormNameIconColor: $klThemeColor2;

$klFormContactRow2BgColor: #f8f8f8;
$klFormContactRow2BorderBottom: 2px solid #efefef;
$klFormIconOtherColor: #aaaaaa;
$klMandatoryFieldNotSetColor: black;
$klFormTitleBgColor: #f8f8f8;
$klChevronColor: $klThemeColor2;
$klCheckBoxContainerBgColor: white;
$klCheckBoxColor: $klThemeColor2;

$klFormHistoryColor: #aaaaaa;

$klSearchFieldBottomBorder: 1px solid #dfdfdf;

$klEmsLuIconsColor: #aaaaaa;
$klEmsLuLabelsColor: #aaaaaa;
$klEmsLuValuesColor: $klTextColor;
$klEmsResetLicenceColor: #aaaaaa;
$klEmsHorizontalBorder: 2px solid #efefef;

$klEmsExportIconColor: $klThemeColor2;

$catSize: 1.5rem;
$menuFontSize: 1.6rem;


$itiTextContentColor : $color2;
$itiDestLabelColor: $color2;
$klLinkBorderColor:$linkColor;
$klLinkTextColor:$linkColor;

// synoptic agenda color of tab selected 
$synopticAgendaTabSelected: red !important;
$vimeoBorderColor: transparent;

$BorderMenuColor: #747474;
$BorderMenuAppversion: #1d1d1f;

$DialogLangButColor:#647276;
$DialogLangColor: #E7E7E7 ;

$klwarningColor: red;

