
@import "../../../app-customs/config-scss/variables.scss";

$iconLabelSpace: 10px;

.fixedMenu {
    border-right: 1px solid #000;
    .menu-items-container {
        padding: 0;
    }
}
.pmr-container-toggle{
    .react-switch{
        margin-left: 0px;
    }
}
.menu-items-container {
    height: auto !important;
    // background-color: $menuBgColor;
    & > li {
        // border-bottom: 1px solid lightgrey;
        cursor: pointer;
        transition: background .4s;
        padding-left: 1em;
        -webkit-tap-highlight-color: transparent;
        // -webkit-tap-highlight-color: $menuItemHoverBackgroundColor;
        
        border: 1px solid #000;
        border-top: none;
        border-right: none;
        &:last-child {
            border-bottom: none;
        }
        &:nth-child(-n+6){ // first 6 li on menu
            background: #f2f2f2;
        }
        &:hover {
            // background-color: $menuItemHoverBackgroundColor;
        }
    }

    .menu-item {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }

 
    svg, path {
        fill: $menuItemIconsBackgroundColor;
    }
    

    .menu-item-switch {
        justify-content:flex-end;
        margin-left: 1em;
    }

    .menu-item-disabled {
        font-weight: bold;
        opacity: .2;
        background-color: lightgrey;
    }

    [tappable] {
        cursor: pointer;
    }
    .menu-icon {
        width : 25px;
        height: 25px;
        margin-right: 10px;
        text-align: center;
        background-repeat: no-repeat;
        background-position-y: center;
    }
    .menu-text {
        user-select: none;
        font-variation-settings: "wght" 900;
    }

    .menu-text-current-profile {
        text-align: center;
        font-weight: bold;
        color: grey;
    }

    /* Language */
    .flag-icon {
        height: 1.6em;
        margin-top: -.2em;
        font-size : .8em;
        background-size: $menuLangIconSize;
        background-position: $menuLangIconPosition;
    }

    // Onsen style override
    .list-item__center {
        border-bottom: 1px solid lightgrey;
        padding: $iconLabelSpace 6px $iconLabelSpace 0;
    }

    // Toggle location
    .list-item-toggle-loc {
        display: flex;

        .menu-item {
            height: 30px;
        }

        .react-switch {
            // We handle click on the whole menu item
            pointer-events: none;
            margin-left: 15px;
        }
    }
    .list-item-toggle-loc-label {
        // Beware: padding-top will vary depending on the font
        padding: 5px $iconLabelSpace 0;
    }
}
.is-web .menu-items-container [tappable]:hover {
    // background-color: #f2f2f2;
}

li.menu-entry-app-version {
    cursor: default;

    .menu-text {
        position: absolute;
        right: 10px;
        font-size: .8em;
        color: #ababab;
    }
}
.menu-entry-app-version:hover {
    // background-color: $pageBackgroundColor;
}

.menu-item-switch {
    // Applies not only for the menu
    pointer-events: none;
    display: flex;
}


// Backdrop style
.MuiDrawer-root .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1);
}


// Fixes IE / Edge
.is-ie {
    .menu-item {
        padding-left: 6px;
    }
    .menu-text {
        padding-bottom: 5px;
    }
}
.is-old-edge {
    .menu-item {
        padding-left: 10px;
    }
}

.pmr-container-toggle {
    // border-style: solid;
    // border-width: 1px;
    // border-color: black; 
    padding: 10px;
    // width: 100%;
    display: flex;
    flex-direction: row;
    // justify-content: space-between; 
    align-items: center;
    margin: 10px;
    // > span {
    //     font-size: 2em;
    // }
    span.gi {
        // background-color: #AAA;
        height: 2em;
        width: 2em;
        background-image: url(../../../app-customs/public/icons/pmr.svg);
        mask-position: center;
        mask-repeat: no-repeat;
    }
}





