
@import "./variables.scss";


.menu-items-container {
    // min-width: 12vw;
    .menu-item {
        align-items: center;
    }
    li{
        color: $listTitleColor;
      &.active  {
          background-color: $color1;
          color: $titleFontColor;
      }
      &:hover {
        //   color: white;
      }
    }
    .menu-icon {
        width : 33px;
        height: 33px;
        padding-right: 10px;
        text-align: center;
        background-repeat: no-repeat;

        &.icon-font {
            font-size: 1.4em;
        }
        &.fa {
            font-size: .9em;
        }
    }
}

// Raise text padding due to printania font
.list-el-text,
.menu-items-container .menu-text {
    // padding-top: .4em;
    font-size: $menuFontSize;
    // font-weight: bolder;
}
.toolbar > .center {
    padding-top: .2em;
}
.type-bar-label {
    padding-top: 3px;
}
.is-android .type-bar-count {
    padding: 0 4px 0 3px;
}

// Category counter
.is-android .list-el-counter > span {
    padding: .5em .3em .2em;
}
.is-ie .list-el-counter > span {
    padding-top: .2em;
    padding-bottom: .4em;
}
.list-el-counter > span {
    padding-top: .4em;
    padding-bottom: .2em;
}

.prop-name.title .prop-desc {
    padding-top: .2em;
}

#screensaver { 
    position: absolute; 
    display: none; 
    width: 100vw; 
    height:100vh; 
    left:0px; 
    top: 0px; 
    z-index:9999; 
}
#screensaver.screensaver_show {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
}
#screensaver.screensaver_hide {
    opacity: 0;
    transition: opacity 400ms;
}

.content-below-apptoolbar {
    height: 100%;
    width: 100%;
}

// DROP SHADOW
.app-toolbar.at-with-dropshadow {
    display: none !important;
}

// Two columns mode
.page-container.left {
    display: flex !important;
    flex-direction: row;
}

.keyboardContainer {
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
//   height: auto;
}
.keyboardContainer.hidden {
  display: none;
}

.menu-items-container {
    & > li {
        height: calc((100vh - 1px) / 7 + 1px) !important;
        // -webkit-tap-highlight-color: $menuItemHoverBackgroundColor;
        text-transform: uppercase;
        text-align: center;
        align-items: center;
        // padding-top: 14px;
        // padding-bottom: 14px;
        justify-content: center;
        flex-direction: row;
        text-decoration: none;
    }
    .menu-icon {
        // display: none;
    }
}

.list-content-container {
    // background-color: $fdLight;
    border-right: 1px solid #000;
    color: white;
}

.list-component {
    color : $listTitleColor;
    font-variation-settings: 'wght' 500;
}

.list-collapse > li {
    background-color: $listBackgroundColor;
    color: $listTitleColor;
    padding-top: 1em;
    padding-bottom: 1em;
    .itemWrapper {
        font-variation-settings: 'wght' 500;
    }
}

.list-collapse > li.horizontal-separator {
    background-color: $separatorBackgroundColor;
    padding: 1.7rem 0;
    font-size: 1.5rem;
}

.not-ios.is-web .list-component li[data-is-clickable]:hover {
    // background-color: #aaa;
}

.alphalist-container {
    .list-component .list-collapse li[data-is-clickable]:hover {
        // background-color: #efefef;
    }
}

.item-collapse, .item-child {
    div > li {
        font-size: 1rem;
        height: calc((100vh - 1px) / 7 + 1px);
        box-sizing: border-box;
        .list-el-chevron {
            font-size: 1.5rem;
        }
    }
}

.item-collapse {
    div > li .list-el-text {
        font-size: 1rem;
    }
}

.list-page-container {
    .list-component {
        .list-el-text {
            text-transform: capitalize;
            line-height: 1.4;
            > span:first-child {
                text-transform: uppercase;
            }
            .list-el-details {
                // margin-top: 0.4rem;
                .text-minor{
                    font-size: inherit;
                }
            }
        }
        li[data-type*="categories"] {
            min-height: 5vh;
            .itemWrapper {
                .list-el-text span {
                    text-transform: capitalize;
                }
            }
        }
        .item-collapse {
            li {
                .list-el-text{
                    font-size: 1.3rem;
                }
            }
            .item-collapse {
                li {
                    .list-el-text{
                        font-size: 1rem;
                    }
                } 
            }
        }
    }
}


.nameFloor {
    text-transform: capitalize;
}
.list-page-container{
    .go-back-title {
        text-transform: capitalize;
    }
}

.text-minor {
    font-size: inherit;
}

.title-li-brand {
    text-transform: uppercase;
}

.detail-header {
    .detail-title-container{
        font-size: 1.45rem;
    }
    .detail-title, .detail-place {
        text-transform: capitalize;
    }
}

.iti-summary-label {
    text-transform: capitalize;
}

.text-minor-container {
    flex-direction: column;
}

.fiche-description {
    .prop-name {
        .prop-description {
            flex: 1;
        }
    }
    .fiche-contact {
        flex: 0 30%;
    }
    .logo {
        border: 0;
    }
}

.iti-qr-code-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    box-sizing: border-box;
    padding: 0 1em;
    border-bottom: 1px solid;
    border-top: 1px solid;
    .iti-qr-code-instruction {
        text-transform: uppercase;
        color: black;
    }
}

.side-index {
    font-variation-settings: 'wght' 500;
}

.search-field-container {
    input::-moz-placeholder {
        color: #222;
    }
}

.list-dialog {
    .list-dialog-content {
        .type-bar-container {
            .item-child {
                .list-el-text {
                    > span {
                        text-transform: uppercase;
                    }
                    .list-el-details {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}
